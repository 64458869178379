<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav selectedLanguage="hi" page="mod" />

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main mod-page">
            <section id="section-1" class="section-content">
              <h1 class="section-1-blog-head">
                नवीनतम GB MOD ऐप्स 2024
              </h1>
              <div class="mod-wrapper">
                <div class="mod-item">
                  <img src="@/assets/logo-gb.webp" alt="gbwhatsapp" class="mod-item-img"/>
                  <div class="mod-item-content">
                    <h2 class="mod-item-title">GB WhatsApp</h2>
                    <a class="mod-item-btn" href="https://gbwhatapks.com/downloadpage" target="_blank">
                      डाउनलोड करें
                    </a>
                  </div>
                </div>
                <div class="mod-item" v-for="(item, i) in doc.modList" :key="i">
                  <img :src="item.img" :alt="item.title" class="mod-item-img"/>
                  <div class="mod-item-content">
                    <h2 class="mod-item-title">{{ item.title }}</h2>
                    <router-link class="mod-item-btn" :to="{name:`en-${item.name}`}">
                      डाउनलोड करें
                    </router-link>
                  </div>
                </div>
              </div>
              <h2>WhatsApp Mod APKs क्या हैं?</h2>
              <p>
              जहां आधिकारिक व्हाट्सएप ऐप अपडेट के साथ विकसित हो रहा है, वहीं इसकी सीमित सुविधाएं और गति अक्सर उपयोगकर्ताओं की मांगों को पूरा करने में असमर्थ रहती हैं। इस अंतर को भरने के लिए, कुशल डेवलपर्स ने मूल ऐप को संशोधित करके उसमें रोमांचक और व्यावहारिक सुविधाओं को जोड़ा, जो व्हाट्सएप द्वारा प्रदान की गई सुविधाओं से परे हैं। नतीजतन, GB व्हाट्सएप, फौआद व्हाट्सएप और यो व्हाट्सएप जैसे लोकप्रिय मॉड विकसित किए गए।
              </p>
              <p>
              इस पेज पर आपको इन WhatsApp Mod APKs के बारे में विस्तृत जानकारी मिलेगी। हम सुरक्षित डाउनलोड लिंक, चरण-दर-चरण गाइड और विशेषताओं की जानकारी प्रदान करते हैं, जिससे आप इन संशोधित ऐप्स का आनंद ले सकें।
              </p>
              <p>
              अब अपनी पसंद का WhatsApp Mod APK डाउनलोड करने में संकोच न करें!
              </p>

            </section>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy">गोपनीयता नीति</a>
          </div>
          <div class="copyright-bar">
            2025 © सभी अधिकार सुरक्षित <strong><router-link :to="{name:`${selectedLanguage}-gb`}">GBWhatsApp</router-link></strong>
          </div>
        </div>
      </footer>
    </div>
    <div v-if="$global.isMobileDevice()" style="height: 52px" aria-hidden="true" class="wp-block-spacer" />

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      GBWhatsapp APK डाउनलोड करें
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import HeadNav from '@/components/HeadNav.vue';
import doc from '@/documents/mods.js';

export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data () {
    return {
      pageName: 'home',
      selectedLanguage: 'hi',
      doc: doc,
    };
  },
  mounted () { },
  methods: {
    gotodownload () {
      let params = window.location.search;
      window.location.href = '/hi/downloadpage' + params;
    },
    jump (url) {
      window.location.href = url;
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>
